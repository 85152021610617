import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
window.Cropper = Cropper

$(document).ready(function() {
  $(document).on('click', 'ul.tabs a', sidebar.ui.tabs.change);
  $(document).on('click', '#modal, #modal .cancel', sidebar.ui.modal.close);
  $(document).on('click', '.as-modal', sidebar.ui.modal.open);
  $(document).on('change', '#user_original_photo', sidebar.tools.cropper.update);

  $('#sidebarCollapse').on('click', function () {
    $('#sidebar').toggleClass('active');
  });
});

let sidebar = {
  ui: {
    modal: {
      close(event) {
        if ( event.target.id == 'modal' || event.target.classList.contains('cancel') ) {
          $('#modal').hide();
          $('#modal').empty();
        }
      },
      open(event) {
        event.preventDefault();
        const button = $(event.target).closest('.as-modal');
        const url = button.prop('href');
        $('#modal').empty();
        $('#modal').load(url);
        $('#modal').fadeIn();
        setTimeout( sidebar.tools.cropper.init, 500 );
      },
    },
    tabs: {
      change(event) {
        event.preventDefault();
        const tab = $(event.target);
        $('ul.tabs .active').removeClass('active');
        tab.addClass('active');
        $('.tabbed-content .active').removeClass('active');
        $('.tabbed-content ' + tab.attr('href')).addClass('active');
      },
    },
  },
  tools: {
    cropper: {
      init: function() {
        const image = $('#original');
        if ( ! image.length ) { return; }

        if (window.cropper) {
          window.cropper.destroy();
        }

        window.cropper = new Cropper(image[0], {
          aspectRatio: 1 / 1,
          crop(event) {
            $('#user_crop_h').val(event.detail.height);
            $('#user_crop_w').val(event.detail.width);
            $('#user_crop_x').val(event.detail.x);
            $('#user_crop_y').val(event.detail.y);
          },
          data: {
            height: image.data('h'),
            width: image.data('w'),
            x: image.data('x'),
            y: image.data('y'),
          },
          viewMode: 3,
        });
      },
      update: function(event) {
        const URL = window.URL || window.webkitURL;
        const input = $(event.target)[0];
        const files = input.files;

        if (files && files.length) {
          const file = files[0];
          if (/^image\/\w+/.test(file.type)) {
            $('#original').css('height', 'initial');
            $('#original')[0].src = URL.createObjectURL( file );
            setTimeout( sidebar.tools.cropper.init, 500 );
          }
        };
      },
    },
  },
};


// TODO: Clean this up some day
// https://htmldom.dev/drag-to-scroll/
document.addEventListener('DOMContentLoaded', function() {
    const ele = document.getElementById('room');

    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function(e) {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        pos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function(e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function() {
        ele.style.cursor = 'initial';
        ele.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    // Attach the handler
    if ( ele ) {
      ele.addEventListener('mousedown', mouseDownHandler);
    }
});
